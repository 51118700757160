import React from 'react'
import { Box } from '@mui/material'
import Layout from '../templates/Master'
import LineBreak from '../components/general/LineBreak'
import Media from '../modules/Media'
import Heading from '../modules/Heading'
import Share from '../modules/Share'
import Content from '../modules/Content'
import Form from '../modules/Form'
import Acccordion from '../modules/Accordion'
import SocialMediaPost from '../modules/SocialMediaPost'

const MediaPage = () => {
    return (
        <Layout>
            <Media media = {[
                "comic-connect-1992-mark-zuckerberg-rookie-card-poster-frame-NFT.jpg",
                "image_2_701659b158.jpg",
                "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg" ]} />
            <Box textAlign="center" mt={8}>
                <Heading as='h1' content='Media Coverage of ComicConnect’s Upcoming Auction' />
            </Box>
            <Content
                alignText="center"
                content="<p>ComicConnect’s upcoming auction of the Authentic 1992 Mark Zuckerberg Rookie SGC-Graded card and NFT has received a lot of attention from the media, industry insiders and influencers in both traditional and digital collectibles. You can see some recent articles and interviews below.</p><p>But there's more mileage in this story, and how people are reacting to one of the most famous business leaders of all time… captured in a joyful childhood moment. We’ll be posting more media links as we approach the auction date of 10 AM EDT Saturday, September 24, 2022.</p><p>Reporters and influencers, please email Stephen Fishler <a href='mailto:stephenf@metropoliscomics.com'>stephenf@metropoliscomics.com</a> for media backgrounders and interviews.</p>" />
            <LineBreak short />
            <Box textAlign="center" my={8}>
                <Heading as='h3' content='What Mark Zuckerberg said on FB' />
            </Box>
            <SocialMediaPost type='facebook' />
            <LineBreak short />
            <Box textAlign="center" mt={8}>
                <Heading as='h3' content='What Mark Zuckerberg said on IG' />
            </Box>
            <Content
                alignText="center"
                content="<p>“In honor of expanding digital collectible NFTs to 100 more countries on Instagram and launching new integrations with Coinbase and Dapper, I᾿m sharing my soon-to-be NFT old little league baseball card, which someone recently found and sent to me... 😂 @mcomicconnect has the backstory on my baseball card.”</p>" />
            <SocialMediaPost />
            <Box mb={8}>
                <Acccordion 
                    data={[
                        {
                            title: "How much is Zuckerberg worth? Card collector could find out",
                            news: "AP NEWS",
                            image: "1000_2d48c96bac.jpg",
                            content: "NEW YORK (AP) — When camp counselor Allie Tarantino was flipping through a magazine years ago, he came upon a familiar name: Mark Zuckerberg. He rifled through boxes of memorabilia in his basement, running his fingers over old photos, newspapers and bus maps before finding a baseball card featuring a very young Zuckerberg grinning in a red jersey and gripping a bat.",
                            date: "Aug 4, 2022",
                            author: "BOBBY CAINA CALVAN",
                            link: "https://apnews.com/article/oddities-mark-zuckerberg-bd143b648b4c03c6480d4abc1e116a61",
                        },
                        {
                            title: "MARK ZUCKERBERG SIGNED LITTLE LEAGUE CARD GOING UP FOR AUCTION ... Nothin' Quite Like It!!!",
                            news: "TMZ",
                            date: "Aug 4, 2022",
                            image: "cdcfa3a5564e457b91e5d247fc0265f0_203b59f3bd.jpg",
                            content: "Mark Zuckerberg was apparently a baseball star for a very small part of his life, and a signed piece of that history is hitting the auction block.",
                            link: "https://www.tmz.com/2022/08/04/mark-zuckerberg-sign-little-league-baseball-card-auction/",
                        },
                        {
                            title: "Mark Zuckerberg Baseball Card Going Up for Auction",
                            news: "Fox News",
                            date: "Aug 4, 2022",
                            image: "1000_1_f58fd03a50.jpg",
                            content: "When camp counselor Allie Tarantino was flipping through a magazine years ago, he came upon a familiar name: Mark Zuckerberg. He rifled through boxes of memorabilia in his basement, running his fingers over old photos, newspapers and bus maps before finding a baseball card featuring a very young Zuckerberg grinning in a red jersey and gripping a bat.",
                            link: "https://www.foxnews.com/lifestyle/mark-zuckerberg-baseball-card-going-auction",
                        },
                        {
                            title: "How Much Is Zuckerberg Worth? Card Collector Could Find Out",
                            news: "US NEWS",
                            date: "Aug 4, 2022",
                            image: "1000_2d48c96bac.jpg",
                            content: "NEW YORK (AP) — When camp counselor Allie Tarantino was flipping through a magazine years ago, he came upon a familiar name: Mark Zuckerberg. He rifled through boxes of memorabilia in his basement, running his fingers over old photos, newspapers and bus maps before finding a baseball card featuring a very young Zuckerberg grinning in a red jersey and gripping a bat.",
                            link: "https://apnews.com/article/oddities-mark-zuckerberg-bd143b648b4c03c6480d4abc1e116a61",
                        },
                        {
                            title: "Camp Counselor Selling Signed Baseball Card Of Former Camper Mark Zuckerberg",
                            news: "Huff Post",
                            date: "Aug 4, 2022",
                            image: "1000_1_f58fd03a50.jpg",
                            content: "NEW YORK (AP) — When camp counselor Allie Tarantino was flipping through a magazine years ago, he came upon a familiar name: Mark Zuckerberg. He rifled through boxes of memorabilia in his basement, running his fingers over old photos, newspapers and bus maps before finding a baseball card featuring a very young Zuckerberg grinning in a red jersey and gripping a bat.",
                            author: "Bobby Caina Calvan",
                            link: "https://www.huffpost.com/entry/mark-zuckerberg-baseball-card_n_62ebe4dbe4b00f4cf2385ecf",
                        },
                        {
                            title: "How much is a Mark Zuckerberg card worth? We're going to find out",
                            news: "MLB",
                            date: "Jan 15, 2011",
                            image: "kkepgrfh2e5819iwaayt_590fb515aa.jpg",
                            content: "The card is of a nine-year-old Mark Zuckerberg from Elmwood Day Camp in Westchester, NY. Camp counselor Allie Tarantino found it a few years ago in a box of memorabilia -- Zuckerberg had given it away when he left the camp three decades ago -- and now he's putting it up for auction as both a physical card and NFT.",
                            author: "Matt Monagan",
                            link: "https://www.mlb.com/news/mark-zuckerberg-has-a-baseball-card",
                        },
                        {
                            title: "Mark Zuckerberg’s custom Little League baseball card is getting turned into an NFT",
                            news: "The Verge",
                            date: "Aug 4, 2022",
                            image: "comicconnect-mark-zuckerberg-rookie-card-front-middle.jpg",
                            content: "Other than “turning up the heat a little bit” on Meta employees who shouldn’t be there and hitting the metaverse runway, Mark Zuckerberg is highlighting Instagram’s expanded NFT support. The founder and CEO took the opportunity of announcing expanded NFT support on Instagram to tell everyone his custom-made 1992 Little League baseball card is going on sale soon, with an NFT included as part of the deal.",
                            author: "Richard Lawler",
                            link: "https://www.theverge.com/2022/8/4/23292066/instagram-nft-mark-zuckerberg-baseball-card-metaverse",
                        },
                        {
                            title: "How Much Would You Pay For A Mark Zuckerberg Rookie Card?",
                            news: "Jon Chattman",
                            date: "Aug 4, 2022",
                            video: "https://www.youtube.com/watch?v=dqbKXh8jOOo&ab_channel=JonChattman",
                            content: "Jon Chattman interviews Mark Rubin of American Legends in Scarsdale, NY to determine how much a signed Mark Zuckerberg card goes for.",
                            author: "Jon Chattman",
                            link: "https://www.youtube.com/watch?v=dqbKXh8jOOo&ab_channel=JonChattman"
                        },
                        {
                            title: "How Much Is Zuckerberg Worth? Card Collector Could Find Out",
                            news: "Bloomberg",
                            date: "Aug 4, 2022",
                            image: "1000_2d48c96bac.jpg",
                            content: "New York (AP) -- When camp counselor Allie Tarantino was flipping through a magazine years ago, he came upon a familiar name: Mark Zuckerberg. He rifled through boxes of memorabilia in his basement, running his fingers over old photos, newspapers and bus maps before finding a baseball card featuring a very young Zuckerberg grinning in a red jersey and gripping a bat.",
                            author: "BOBBY CAINA CALVAN",
                            link: "https://www.bloomberg.com/news/articles/2022-08-04/how-much-is-zuckerberg-worth-card-collector-could-find-out",
                        }
                    ]}/>
            </Box>
            <LineBreak />
            <Box textAlign="center" mt={8}>
                <Heading as='h3' variant='h4' content='Sign up to receive auction updates!' width={['auto', 'auto', 'auto', 'auto', 800]} />
            </Box>
            <Content
                alignText="center"
                content="<p>Don't miss this historic event! Please sign up here to stay up to date and receive sneak previews!</p>"
                width={['auto', 'auto', 'auto', 'auto', 800]}/>
            <Form />
            <LineBreak />
            <Box textAlign="center" my={8}>
                <Heading as='h2' width={['auto', 'auto', 'auto', 'auto', 500]} content='Tell your friends about the Mark Zuckerberg baseball card & NFT auction!' />
            </Box>
            <Box textAlign="center" mb={5}>
                <Share />
            </Box>
        </Layout>
    )
}

export default MediaPage
