import React from 'react'
import { Box, Grid } from '@mui/material'
import Image from '../components/Images'
import NavLink from '../components/nav/NavLink'

const MediaPost = ({ type }) => {
    let link = null
    let image = null
    switch (type) {
    case 'facebook':
        link = 'https://www.facebook.com/photo.php?fbid=10114610261296041&set=a.612287952871&type=3'
        image = 'zuckerberg-facebook-post.png'
        break
    default: //Instagram
        link = 'https://www.instagram.com/p/Cg1wA01l3NX/?utm_source=ig_embed&ig_rid=e1045769-3eed-4cbc-836d-68f0c310c5b1'
        image = 'zuckerberg-instagram-post.png'
        break
    }
    return (
        <Grid container='true' justifyContent='center'>
            <Box mb={8}>
                <NavLink external to={link} >
                    <Image height='auto' src={image} width='320px' />
                </NavLink>
            </Box>
        </Grid>
    )
}

export default MediaPost
